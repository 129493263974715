import React, { useContext, createContext, useState } from "react";
import { ApiContext } from "./ApiContext";
export const UserContext = createContext({
  user: {},
  coInsuranceUser: {},
  socialNr: "",
  email: "",
  setEmail: () => {},
  userInsurance: {},
  coinsurance: {},
  coSocialNumber: {},
});

export const UserProvider = ({ children }) => {
  const { getUser, postInsurance, postCoinsurance } = useContext(ApiContext);
  const [user, setUser] = useState(undefined);
  const [coInsuranceUser, setCoInsuranceUser] = useState(undefined);
  const [userInsurance, setUserInsurance] = useState(undefined);
  const [socialNr, setSocialNr] = useState("");

  const [coinsurance, setCoinsurance] = useState(undefined);
  const [coSocialNumber, setCoSocialNumber] = useState("");

  const fetchData = async (ssn, cUid) => {
    const userResponse = await getUser(ssn, cUid);
    setSocialNr(ssn);
    if (userResponse === undefined) {
      console.log("sorry, undifined");
    } else {
      setUser(userResponse);
    }
  };

  const setEmail = (email) => {
    setUser({
      ...user,
      email,
    });
  };

  const postInsuranceData = async (body) => {
    const { email } = user;
    const { campaignUid, paymentMethod, paymentPeriod } = body;
    const userResponse = await postInsurance({
      CustomerCode: socialNr,
      campaignUid,
      email,
      paymentMethod,
      paymentPeriod,
    });
    await userResponse.then(setCoInsuranceUser(userResponse));
  };

  const postData = async (body) => {
    const { customerCode, coCustomerCode, campaignUid } = body;
    await postCoinsurance({
      customerCode,
      coCustomerCode,
      campaignUid,
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        coInsuranceUser,
        setCoInsuranceUser,
        socialNr,
        fetchData,
        setEmail,
        userInsurance,
        setUserInsurance,
        coinsurance,
        setCoinsurance,
        coSocialNumber,
        setCoSocialNumber,
        postData,
        postInsuranceData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
