import React from "react";
import ReactDom from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalInfo.css";
import strings from "../../strings";

const Modal = ({ show, close, title, children }) => {
  const { titleWorking, subWorking, linkToExtarnalTerms, infoGreenCardSystem } =
    strings.homeViewFooterModal;

  return ReactDom.createPortal(
    <>
      {show ? (
        <div className="modal-main" onClick={() => close()}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <header className="modal-header">
              <div className="icon-close">
                <FontAwesomeIcon
                  icon="times"
                  className="close"
                  onClick={() => close()}
                />
              </div>
              <p className="modal-header-title">{title}</p>
              <div className="border-content" />
            </header>
            <main className="modal-content">{children}</main>
            <footer className="modal-footer-container">
              <div className="border-content" />
              <div className="footer-info">
                <span className="sub-title-footer">{titleWorking}</span>
                <p className="main-info-footer">{subWorking}</p>
                <a
                  className="link-info-footer"
                  href="https://assistansbolaget.nu/villkor/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {linkToExtarnalTerms}
                </a>
                <p className="link-info-footer">{infoGreenCardSystem}</p>
              </div>
            </footer>
          </div>
        </div>
      ) : null}
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
