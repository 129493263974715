import React, { useEffect } from "react";
import "./HomePage.css";
import "./PageNotFound.css";
import Logo from "../common/Logo";
import { usePrismicDocumentByUID } from "@prismicio/react";
import BackgroundImage from "../common/BackgroundImage";
import { useNavigate, useParams } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const [dynamicUid, setDynamicUid] = React.useState();
  let { uid } = useParams();

  useEffect(() => {
    if (uid) {
      setDynamicUid(uid);
    } else {
      setDynamicUid("bil");
    }
  }, [uid]);

  const [campaign] = usePrismicDocumentByUID("campaign", `${dynamicUid}`);

  const navigateHome = () => {
    navigate(`/${dynamicUid}`);
  };

  return (
    <>
      {campaign && (
        <>
          <div className="page-not-found-main-container">
            <div className="logo-container">
              <Logo />
            </div>
            <div className="page-not-found-container">
              <p className="page-not-found-header">Något gick fel</p>
              <p onClick={navigateHome} className="page-not-found-link">
                Tillbaka till startsidan
              </p>
            </div>
          </div>
        </>
      )}
      {campaign && (
        <div className="background">
          <BackgroundImage />
        </div>
      )}
    </>
  );
};

export default PageNotFound;
