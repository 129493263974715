import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import strings from "../../../strings";
import ButtonMain from "../ButtonMain";
/* import useNavigateWithRootParams from "../../../hooks/useNavigateWithRootParams"; */
import "./InsuranceSubmitPage.css";
import { usePrismicDocumentByUID } from "@prismicio/react";
import LoadingSpinner from "../LoadingSpinner";
import { ApiContext } from "../../../context";
import ModalPopUp from "../ModalPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const InsuranceSubmitComponent = () => {
  /* const navigate = useNavigateWithRootParams(); */
  const { uid: campaignUid } = useParams();
  const { user } = useContext(UserContext);
  const { insuranceState } = useContext(ApiContext);
  const postUser = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(false);
  const [userInformation, setUserInformation] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [showInput, setShowInput] = useState(false);

  const [paymentPeriod, setPaymentPeriod] = useState(12);
  let { uid } = useParams();

  const [campaign] = usePrismicDocumentByUID("campaign", `${uid}`);

  const [showTerms, setShowTerms] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const handleIconClick = () => {
    setIsRotated(!isRotated);
    setShowTerms(!showTerms);
  };

  const {
    titlePayment,
    period,
    applyAuto,
    conditionsTitle,
    conditionsInsurance,
    submit,
  } = strings.signInsuranceView;
  const {
    titleAuto,
    titlePaper,
    quarter,
    pricePerYear,
    pricePerMonth,
    halfYear,
    year,
  } = strings.paymentInsuranceView;

  const [warningText, setWarningText] = useState(false);

  const handleCheck = (event) => {
    event.stopPropagation();
    setIsChecked(!isChecked);
  };

  const handlePayment = (event) => {
    const target = event.target;
    if (target.checked) {
      setPaymentPeriod(target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!isChecked) {
      setWarningText(true);
    } else if (paymentMethod === 1) {
      postUser.postInsuranceData({
        paymentMethod: paymentMethod,
        paymentPeriod: paymentPeriod,
        campaignUid,
      });
      window.open("https://ag.assistansbolaget.nu");
    } else {
      setIsChecked(true);
      postUser.postInsuranceData({
        paymentMethod: paymentMethod,
        paymentPeriod: paymentPeriod,
        campaignUid,
      });
    }
  };

  const handleChange = (event) => {
    event.stopPropagation();
    setUserInformation(event.target.value);
    console.log(userInformation);
  };

  const onClickInput = (event) => {
    event.preventDefault();
    setShowInput(true);
  };

  const insurancePeriod = () => {
    const newDate = new Date();
    const todaysDate = newDate.toISOString().substring(0, 10);
    const nextYearDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    const nextYearToISO = nextYearDate.toISOString().substring(0, 10);
    return (
      <>
        {todaysDate} - {nextYearToISO}
      </>
    );
  };

  return (
    <>
      {insuranceState.loading && <LoadingSpinner />}
      {campaign && (
        <div className="insurance-wrapper">
          <div className="insurance">
            <div className="task">
              <header>
                <p className="title-task border">{titlePayment}</p>
                <div className="border-content"></div>
              </header>

              <main className="maint-task-wrapper">
                <div className="customer-info-container">
                  <p className="user-info ">
                    {user?.firstName} {user?.lastName}
                  </p>
                  <p className="user-info ">{user?.streetAddress}</p>
                  <p className="user-info ">
                    {user?.zipCode} {user?.city}
                  </p>
                </div>

                <div className="row-car-container">
                  <h5>{campaign.data.product}</h5>
                  <p className="insurance-period">
                    {period} {insurancePeriod()}
                  </p>
                  <p className="premium-price">
                    Premie: {campaign.data.price} {pricePerYear}
                  </p>
                  <p className="apply-autogiro">{applyAuto}</p>
                </div>
              </main>

              <footer className="footer-content">
                <div className="conditions-insurance">
                  <div className="conditions-wrapper">
                    <span style={{ display: "flex" }}>
                      <label className="checkbox-container">
                        <input
                          className={`select-check`}
                          type="checkbox"
                          onChange={handleCheck}
                          defaultChecked={isChecked}
                          checked={isChecked}
                        />
                        <span
                          className={`span-tag-checkbox ${
                            warningText ? "warningText" : ""
                          }`}
                        ></span>
                      </label>
                      <p className="condition-title">{conditionsTitle}</p>
                    </span>
                    <div
                      className={`rotate-icon ${isRotated ? "rotate" : ""}`}
                      onClick={handleIconClick}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </div>
                  {showTerms && (
                    <p className="condition-text">{conditionsInsurance}</p>
                  )}
                </div>
              </footer>
            </div>
          </div>

          <div className="insurance-btn-wrapper">
            <form className="form-input-radio" onChange={handleChange}>
              <div
                className="btn-auto-container"
                onClick={() => setPaymentMethod(1)}
              >
                <input
                  className="input-auto-paper"
                  type="radio"
                  name="paymentMethod"
                  value={paymentMethod}
                  checked={paymentMethod === 1}
                />
                <label
                  className="title-auto-paper"
                  onClick={() => setShowInput(false)}
                >
                  {titleAuto}
                  <p className="price-month">
                    ({Math.floor(campaign.data.price / 12)} {pricePerMonth})
                  </p>
                </label>
              </div>
              <div
                className="btn-auto-container"
                onClick={() => setPaymentMethod(4)}
              >
                <input
                  className="input-auto-paper"
                  type="radio"
                  name="chosePaperOrAuto"
                  value={paymentMethod}
                  checked={paymentMethod === 4}
                />
                <label className="title-auto-paper" onClick={onClickInput}>
                  {titlePaper}
                  <p className="price-month">
                    ({Math.floor(campaign.data.price / 12) + 6} {pricePerMonth})
                  </p>
                </label>
              </div>
            </form>
          </div>
          {showInput && (
            <form className="section" onSubmit={handleSubmit}>
              <div className="input-radio" onClick={() => setPaymentPeriod(4)}>
                <input
                  className="select-period"
                  type="radio"
                  name="payment"
                  value={4}
                  onChange={handlePayment}
                  checked={paymentPeriod === 4}
                />
                <label className="title-payment" htmlFor="quarter">
                  {quarter}
                </label>
              </div>
              <div className="input-radio" onClick={() => setPaymentPeriod(6)}>
                <input
                  className="select-period"
                  type="radio"
                  name="payment"
                  value={6}
                  onChange={handlePayment}
                  checked={paymentPeriod === 6}
                />
                <label className="title-payment" htmlFor="half-year">
                  {halfYear}
                </label>
              </div>
              <div className="input-radio" onClick={() => setPaymentPeriod(12)}>
                <input
                  className="select-period"
                  type="radio"
                  value={12}
                  name="payment"
                  onChange={handlePayment}
                  checked={paymentPeriod === 12}
                />
                <label className="title-payment" htmlFor="year">
                  {year}
                </label>
              </div>
            </form>
          )}
        </div>
      )}

      <div className="content-price-btn">
        <ButtonMain type="submit" onClick={onSubmit} title={submit} />
      </div>
      <ModalPopUp />
    </>
  );
};

export default InsuranceSubmitComponent;
