import React from "react";
import "./ListInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoPopup from "./InfoPopup";
import strings from "../../strings";

const ListInfo = () => {
  const {
    titleInsurance,
    bookingAndPaying,
    twentyFourSeven,
    zeroDeductible,
    question,
    callUs,
    phoneNumber,
  } = strings.homeViewListInfo;

  return (
    <>
      <div className="content-info content-center">
        <InfoPopup />

        <ul className="info-text">
          <div className="icon-list-container">
            <FontAwesomeIcon icon="check" className="icon-check" />
            <li className="list-info">{titleInsurance}</li>
          </div>

          <div className="icon-list-container">
            <FontAwesomeIcon icon="check" className="icon-check" />
            <li className="list-info">{bookingAndPaying}</li>
          </div>
          <div className="icon-list-container">
            <FontAwesomeIcon icon="check" className="icon-check" />
            <li className="list-info">{twentyFourSeven}</li>
          </div>
          <div className="icon-list-container">
            <FontAwesomeIcon icon="check" className="icon-check" />
            <li className="list-info"> {zeroDeductible}</li>
          </div>
        </ul>
        <div className="phone-nr-container">
          <p className="phone-nr">{question}</p>
          <p className="phone-nr">
            {callUs}
            <a href="tel:+4642324324" className="phone-nr">
              {phoneNumber}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ListInfo;
