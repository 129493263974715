import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import strings from "../../strings";
import SvgIcon from "./SvgIcon";

const SuggestionsVehicle = () => {
  const { user, socialNr } = useContext(UserContext);
  const postVehicle = useContext(UserContext);
  const { addButton } = strings.suggestionsFinish;
  const { ordPriceSuggestions, priceYear } = strings.vehicleSuggestions;
  const [vehiclesArray, setVehiclesArray] = useState([]);
  useEffect(() => {
    setVehiclesArray(user.insuranceSuggestions);
  }, []);

  const handleSubmit = (event, campaignUid, index) => {
    vehiclesArray.splice(index);
    event.preventDefault();

    /* const element = event.currentTarget.parentNode.parentNode; */
    /*     const message = document.querySelector(".message");
    const container = document.querySelector(".relations-container"); */

    postVehicle.postInsuranceData({
      customerCode: socialNr,
      campaignUid: campaignUid,
    });
  };

  return (
    <div className="relations-container">
      <p className="relations-header">
        Erbjudande för att försäkra dina andra fordon
      </p>
      {vehiclesArray.map((suggestion, index) => (
        <>
          <div className="card-container" key={index}>
            <div className="svg-icon">
              <SvgIcon
                className="icon-svg"
                articleType={suggestion.articleType}
              />
            </div>
            <div className="card-text">
              <p className="title-regular-text">{suggestion.product}</p>
              <div className="fee">
                <div className="campaign-price amount">
                  <p className="suggestion-price">{suggestion.price}</p>
                  <p className="campaign-price text">{priceYear}</p>
                </div>
                <p className="ordinary-price">
                  ({ordPriceSuggestions} {suggestion.ordinaryPrice} {priceYear})
                </p>
              </div>
              <button
                className="sign"
                type="submit"
                onClick={(e) => handleSubmit(e, suggestion.campaignUid, index)}
              >
                {addButton}
              </button>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default SuggestionsVehicle;
