import { useState } from "react";
import ModalInfo from "./ModalInfo";
import "./InfoPopup.css";
import strings from "../../strings";
import { usePrismicDocumentByUID } from "@prismicio/react";
import { useParams } from "react-router-dom";

const InfoPopup = () => {
  const { subTitle } = strings.homeView;
  const {
    onlyForFirstText,
    onlyForSecondText,
    ordinaryPricePerYear,
    kr,
    personalInsteadOfVehicle,
    personalInsurance,
    titelBookingAndPay,
    subBookingAndPay,
    titleInsurance,
    subInsurance,
    titleCustomerBenefits,
    subCustomerBenefits,
  } = strings.homeViewContentModal;

  let { uid } = useParams();
  const [campaign, { state }] = usePrismicDocumentByUID("campaign", `${uid}`);

  const [modal, setModal] = useState(false);
  const ToggleModal = () => setModal(!modal);

  return (
    <>
      {state === "loading" ? (
        <p>Loading…</p>
      ) : (
        campaign && (
          <div className="modal-conatiner">
            <h2 className="main-title">{campaign.data.product}</h2>
            <p className="link-popup" onClick={() => ToggleModal()}>
              {subTitle}
            </p>

            <ModalInfo
              show={modal}
              close={ToggleModal}
              title={campaign.data.product}
            >
              <p className="sub-text">
                {onlyForFirstText} {campaign.data.product} {onlyForSecondText}
              </p>
              <p className="main-info price-info">
                {campaign.data.price} {ordinaryPricePerYear}
                {campaign.data.ordinary_price} {kr}.
              </p>
              <span className="title-info">{personalInsurance}</span>
              <p className="main-info">{personalInsteadOfVehicle}</p>

              <span className="title-info">{titelBookingAndPay}</span>
              <p className="main-info">{subBookingAndPay}</p>

              <span className="title-info">{titleInsurance}</span>
              <p className="main-info">{subInsurance}</p>
              <span className="title-info">{titleCustomerBenefits}</span>
              <p className="main-info">{subCustomerBenefits}</p>
            </ModalInfo>
          </div>
        )
      )}
    </>
  );
};

export default InfoPopup;
