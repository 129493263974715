import React, { useContext, useState } from "react";
import { UserContext } from "../../../context";
import ButtonMain from "../ButtonMain";
import strings from "../../../strings";
import "../common/CommonStyles.css";
import useNavigateWithRootParams from "../../../hooks/useNavigateWithRootParams";
import { usePrismicDocumentByUID } from "@prismicio/react";
import { useParams } from "react-router-dom";

const ReviewCutomerPage = () => {
  const navigate = useNavigateWithRootParams();
  const { user, setEmail } = useContext(UserContext);
  const { submit, titleCheck, emailPlaceholder } = strings.checkView;

  const [emailState, setEmailState] = useState("");

  const [danger, setDanger] = useState(true);
  const [warningText, setWarningText] = useState(false);

  let { uid } = useParams();
  const [campaign, { state }] = usePrismicDocumentByUID("campaign", `${uid}`);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (value.length > 0) {
      setDanger(true);
    }
    setEmailState(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (emailState === "") {
      setDanger(false);
      setWarningText("E-post måste fyllas i");
    } else if (!emailRegex.test(emailState)) {
      setDanger(false);
      setWarningText("E-post är inte giltig");
    } else {
      setDanger(true);
      setEmail(emailState);
      navigate("forsakring");
    }
  };

  return (
    <>
      {state === "loading" ? (
        <p>Loading…</p>
      ) : (
        campaign && (
          <>
            <div className="content-info-container">
              <div className="task-container">
                <p className="title-task border">{titleCheck}</p>
                <div className="border-content" />
                <p className="user-info">
                  {user?.firstName} {user?.lastName}
                </p>
                <p className="user-info">{user?.streetAddress}</p>
                <p className="user-info">
                  {user?.zipCode} {user?.city}
                </p>
                <div className="car-price">
                  <p className="row-car">{campaign.data.product}</p>
                  <p className="price-year">{campaign.data.price} kr/år</p>
                </div>
              </div>
            </div>
            <div className="input-container">
              <form className="form-input" onSubmit={onSubmit} noValidate>
                <p
                  className={`danger-container text-danger ${
                    danger ? "danger" : ""
                  }`}
                >
                  {warningText}
                </p>
                <input
                  className={`input-user ${warningText ? "warningText" : ""}`}
                  type="email"
                  value={emailState}
                  placeholder={emailPlaceholder}
                  htmlFor="email"
                  name="email"
                  onChange={handleChange}
                />

                <ButtonMain title={submit} type="submit" />
              </form>
            </div>
          </>
        )
      )}
    </>
  );
};
export default ReviewCutomerPage;
