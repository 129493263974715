import React from "react";
import "./Logo.css";
import LogoImage from "../../../assets/assistansbolaget_logo_neg.png";

const Logo = () => {
  return (
    <div className="container-logo">
      <img className="logo" src={LogoImage} alt="Logo" />
    </div>
  );
};

export default Logo;
