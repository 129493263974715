import React, { createContext, useState } from "react";
import axios from "axios";

export const ApiContext = createContext({
  getUser: () => {},
  postInsurance: () => {},
  postCoinsurance: () => {},
  userState: {},
  setUserState: () => {},
  insuranceState: {},
  coInsuranceState: {},
});

const PROD_CAMPAIGN = "https://campaign.assi.nu";
const STG_CAMPAIGN = "https://campaign.stg.assi.nu";
const DEV_CAMPAIGN = "https://campaign.dev.assi.nu";

function getBaseUrl() {
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_ENV === "PRODUCTION") {
    return PROD_CAMPAIGN;
  }
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_ENV === "STAGING") {
    return STG_CAMPAIGN;
  }
  return DEV_CAMPAIGN;
}

export const ApiProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    loading: false,
    response: null,
    error: null,
  });

  const [insuranceState, setInsuranceState] = useState({
    loading: false,
    response: null,
    error: null,
  });

  const [coInsuranceState, setCoInsuranceState] = useState({
    loading: false,
    response: null,
    error: null,
  });

  async function getUser(socialNr, cUid) {
    const url = `${getBaseUrl()}/v1/customers?customerCode=${socialNr}&articleType=${cUid}`;
    try {
      setUserState({
        loading: true,
        response: null,
        error: null,
      });
      const response = await axios.get(url).then((res) => {
        setUserState({
          loading: false,
          response: res,
          error: null,
        });
        return res.data;
      });
      if (!response) {
        throw new Error(`Sorry, there is an error: ${response.message}`);
      }
      return response;
    } catch (err) {
      console.log(err);
      setUserState({
        loading: false,
        response: null,
        error: err,
      });
    }
  }

  const defaultInsuranceInfo = {
    customerCode: "",
    email: "",
    paymentMethod: undefined,
    paymentPeriod: undefined,
    campaignUid: undefined,
  };

  async function postInsurance(insuranceInformation = defaultInsuranceInfo) {
    const url = `${getBaseUrl()}/v1/customers`;
    try {
      setInsuranceState({
        loading: true,
        response: null,
        error: null,
      });
      const data = await axios
        .post(url, insuranceInformation, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setInsuranceState({
            loading: false,
            response: res,
            error: null,
          });
          return res.data;
        })
        .catch((error) => {
          setInsuranceState({
            loading: false,
            response: null,
            error: error,
          });
        });
      return data;
    } catch (err) {
      const message =
        typeof err.response !== "undefined"
          ? err.response.data.message
          : err.message;
      console.warn("error message", message);
      return err;
    }
  }

  async function postCoinsurance(body) {
    const url = `${getBaseUrl()}/v1/customers/coinsurance`;
    try {
      setCoInsuranceState({
        loading: true,
        response: null,
        error: null,
      });
      return await axios
        .post(url, body, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setCoInsuranceState({
            loading: false,
            response: res,
            error: null,
          });
          return res.data;
        })
        .catch((error) => {
          setCoInsuranceState({
            loading: false,
            response: null,
            error: error,
          });
        });
    } catch (err) {
      const message =
        typeof err.response !== "undefined"
          ? err.response.data.message
          : err.message;
      console.warn("error message", message);
      return err;
    }
  }

  return (
    <ApiContext.Provider
      value={{
        getUser,
        postInsurance,
        postCoinsurance,
        userState,
        setUserState,
        insuranceState,
        setInsuranceState,
        coInsuranceState,
        setCoInsuranceState,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
