import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faArrowLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import Layout from "./features/components/layout/Layout";
import HomePage from "./features/components/pages/HomePage";
import SocialnrSubmitPage from "./features/components/pages/SocialnrSubmitPage";
import ReviewCutomerPage from "./features/components/pages/ReviewCutomerPage";
import InsuranceSubmitPage from "./features/components/pages/InsuranceSubmitPage";
import FinishedPage from "./features/components/pages/FinishedPage";
import PageNotFound from "./features/components/pages/PageNotFound";
import LandingPage from "./features/components/pages/LandingPage";

library.add(faCheck, faTimes, faArrowLeft);

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route index path="/" element={<LandingPage />} />
          <Route index path=":uid" element={<HomePage />} />
          <Route path=":uid" element={<Layout />}>
            <Route path="personnummer" element={<SocialnrSubmitPage />} />
            <Route path="granska" element={<ReviewCutomerPage />} />
            <Route path="forsakring" element={<InsuranceSubmitPage />} />
          </Route>
          <Route path=":uid/klar" element={<FinishedPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
