import "./ButtonMain.css";

const ButtonMain = (props) => {
  return (
    <div className="btn-container">
      <button className="btn-submit" type={props.buttontype} {...props}>
        {props.title}
      </button>
    </div>
  );
};

export default ButtonMain;
