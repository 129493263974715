import React, { useContext } from "react";
import Logo from "../../components/common/Logo";
import HighlightedNumbers from "../HighlightedNumbers";
import BackgroundImage from "../common/BackgroundImage";
import "../../components/pages/FinishedPage.css";
import { UserContext } from "../../../context/UserContext";
import { ApiContext } from "../../../context";
import strings from "../../../strings";
import SuggestionsVehicle from "../SuggestionsVehicle";
import Relations from "../Relations";
import LoadingSpinner from "../LoadingSpinner";

const FinishedComponent = () => {
  const { user } = useContext(UserContext);
  const { coInsuranceState, insuranceState } = useContext(ApiContext);
  const { thanksText, theInsuranceIsOkText, confirmationText, linkText } =
    strings.finishedView;

  return (
    <>
      {coInsuranceState.loading && <LoadingSpinner />}
      {insuranceState.loading && <LoadingSpinner />}
      <div className="finish-container finish-wrapper">
        <div className="logo-wrapper">
          <Logo />
        </div>
        <div className="wrapper-container wrapper wrapp-greetings">
          <HighlightedNumbers />
          <div className="greeting">
            <p className="t">{thanksText}</p>
            <p className="p">{theInsuranceIsOkText}</p>
            <span className="terms-wrapper">
              <p className="confirmation-text-italic">{confirmationText}</p>
            </span>
            <p className="confirmation-text-italic">{linkText}</p>
            <a
              href="https://assistansbolaget.nu/villkor/"
              target="_blank"
              rel="noopener noreferrer"
              className="terms-link"
            >
              assistansbolaget.nu/villkor
            </a>
          </div>
          {user?.insuranceSuggestions.length > 0 && <SuggestionsVehicle />}
          {user?.relations.length > 0 && <Relations />}
        </div>
      </div>
      <div className="background">
        <BackgroundImage />
      </div>
    </>
  );
};

export default FinishedComponent;
