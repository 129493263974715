import React from "react";
import "./HomePage.css";
import BackgroundImage from "../common/BackgroundImage";
import Logo from "../common/Logo";
import { useNavigate } from "react-router-dom";
import { usePrismicDocumentByUID } from "@prismicio/react";

const LandingPage = () => {
  /*  const navigate = useNavigateWithRootParams();
  const { submit, ordinaryPrice, pricePerYear } = strings.homeView;
  let { uid } = useParams();
 */

  const navigate = useNavigate();

  const navigateCar = () => {
    navigate("/bil");
  };
  const navigateRV = () => {
    navigate("/husvagn");
  };
  const navigateMC = () => {
    navigate("/mc");
  };

  const [campaign] = usePrismicDocumentByUID("campaign", "bil");

  return (
    <>
      <div className="main-container main">
        <div className="logo-container">
          <Logo />
        </div>
        <p onClick={navigateCar} className="page-not-found-link">
          Till vår bilförsäkring
        </p>
        <p onClick={navigateRV} className="page-not-found-link">
          Till vår husvagnsförsäkring
        </p>
        <p onClick={navigateMC} className="page-not-found-link">
          Till vår mc-försäkring
        </p>
      </div>
      {campaign ? (
        <div className="background">
          <BackgroundImage />
        </div>
      ) : null}
    </>
  );
};

export default LandingPage;
