import React from "react";
import { useLocation } from "react-router-dom";
import "./HighlightedNumbers.css";
import strings from "../../strings";

const HighlightedNumbers = () => {
  const { pathname } = useLocation();

  const {
    nrOne,
    nrTwo,
    nrThree,
    nrFour,
    socialNr,
    socialReview,
    socialPay,
    socialGreat,
  } = strings.stepView;

  const pathnamePersonnummer = pathname.includes("personnummer");
  const pathnameGranska = pathname.includes("granska");
  const pathnameForsakring = pathname.includes("forsakring");
  const pathnameKlar = pathname.includes("klar");

  return (
    <>
      <div className="highlighted-container">
        <ul className="number-container">
          <li className="list-numbers">
            <span
              className={pathnamePersonnummer ? "highlighted" : "unHighlighted"}
            >
              {nrOne}
            </span>
            <span
              className={
                pathnamePersonnummer ? "highlighted-text" : "unHighlighted-text"
              }
            >
              {socialNr}
            </span>
          </li>

          <li className="list-numbers">
            <span className={pathnameGranska ? "highlighted" : "unHighlighted"}>
              {nrTwo}
            </span>
            <span
              className={
                pathnameGranska ? "highlighted-text" : "unHighlighted-text"
              }
            >
              {socialReview}
            </span>
          </li>

          <li className="list-numbers">
            <span
              className={pathnameForsakring ? "highlighted" : "unHighlighted"}
            >
              {nrThree}
            </span>
            <span
              className={
                pathnameForsakring ? "highlighted-text" : "unHighlighted-text"
              }
            >
              {socialPay}
            </span>
          </li>

          <li className="list-numbers">
            <span className={pathnameKlar ? "highlighted" : "unHighlighted"}>
              {nrFour}
            </span>
            <span
              className={
                pathnameKlar ? "highlighted-text" : "unHighlighted-text"
              }
            >
              {socialGreat}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};
export default HighlightedNumbers;
