import React from "react";
import ReactDom from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../features/components/ModalInsurance.css";
import strings from "../../strings";

const ModalInsurance = ({ show, close, title, children }) => {
  const { question, callUs, phoneNumber } = strings.homeViewListInfo;
  return ReactDom.createPortal(
    <>
      {show && (
        <div className="modal-container" onClick={() => close()}>
          <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
            <header className="modal-header-container">
              <div className="icon-close-container">
                <FontAwesomeIcon
                  icon="times"
                  className="close-popup"
                  onClick={() => close()}
                />
              </div>

              <p className="modal-title">{title}</p>

              <div className="border-content" />
            </header>

            <main className="modal-content-main">{children}</main>
            <footer className="modal-footer">
              <div className="border-content"></div>
              <div className="footer-info-text">
                <span className="sub-text-footer">
                  <div className="call-container">
                    <p className="questions"> {question}</p>
                    <p className="call-nr">
                      {callUs}
                      <a href="tel:+4642324324" className="call-nr">
                        {phoneNumber}
                      </a>
                    </p>
                  </div>
                </span>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>,
    document.getElementById("modal")
  );
};

export default ModalInsurance;
