import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePrismicDocumentByUID } from "@prismicio/react";
import "./BackgroundImage.css";
import useMediaQuery from "./useMediaQuery";
const BackgroundImage = () => {
  let { uid } = useParams();

  const [dynamicUid, setDynamicUid] = React.useState();

  useEffect(() => {
    if (uid) {
      setDynamicUid(uid);
    } else {
      setDynamicUid("bil");
    }
  }, [uid]);

  const [campaign] = usePrismicDocumentByUID("campaign", `${dynamicUid}`);

  const isMobile = useMediaQuery("(min-width: 650px)");

  return (
    <>
      <div className="bg-container " id="overlay">
        {isMobile ? (
          <img
            className="bg-image"
            src={campaign?.data.background_image.url}
            alt="desktopImage"
          />
        ) : (
          <img
            className="bg-image"
            src={campaign?.data.mobile_background_image.url}
            alt="mobileImage"
          />
        )}
      </div>
    </>
  );
};

export default BackgroundImage;
