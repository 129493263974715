const strings = {
  loading: {
    loadingText: "Loading...",
  },
  loadingSpinner: {
    loadingFirstText: "VÄNLIGEN VÄNTA...",
  },
  homeView: {
    title: "Vägassistans",
    subTitle: "Läs mer om vår försäkring här",
    ordinaryPrice: "ord. pris:",
    pricePerYear: "kr/år",
    submit: "Teckna nu",
  },
  finishedView: {
    thanksText: "Tack!",
    theInsuranceIsOkText: "Vi har tagit emot din försäkringsanmälan.",
    confirmationText:
      "Du kommer att få ditt försäkringsbrev skickat per epost till den email du angivit och din försäkring kommer att bli aktiv inom 24 timmar. Ett SMS med en bekräftelse om att din försäkring har aktiverats kommer att skickas till dig.",
    linkText: "Du kan läsa mer om dina försäkringsvillkor här:",
  },
  homeViewListInfo: {
    titleInsurance: "Personbunden försäkring",
    bookingAndPaying:
      "Vi bokar och betalar för bärgning, hyrbil, hemresa eller hotell",
    twentyFourSeven: "Dygnet runt i Europa",
    zeroDeductible: "Alltid 0 kr i självrisk!",
    question: "Har du frågor?",
    callUs: "Ring oss på:",
    phoneNumber: " 042-324 324",
  },
  homeViewContentModal: {
    onlyForFirstText: "Just nu kan du teckna vår försäkring för",
    onlyForSecondText: "för endast",
    ordinaryPricePerYear: "kr/år (ord. pris:",
    kr: "kr)",
    personalInsurance: "Personbunden vägassistansförsäkring",
    personalInsteadOfVehicle:
      "Hos oss får du en personbunden försäkring istället för en bilbunden. Det betyder att du som förare har med dig ditt försäkringsskydd i alla bilar* som du kör.",
    titelBookingAndPay: "Vi bokar och betalar",
    subBookingAndPay:
      "Vi hjälper dig med bärgning eller reparation på plats vid ofrivilligadriftstopp samt bokar och betalar hyrbil, hemresa eller hotell för dig och dina medpassagerare – utan att du behöver göra några egna ekonomiska utlägg.",
    titleInsurance: "Dygnet runt i Europa – alltid 0 kr i självrisk",
    subInsurance:
      "Försäkringen gäller i Europa**, alltid med 0 kr i självrisk!",
    titleCustomerBenefits: "Dina extra förmåner som kund",
    subCustomerBenefits:
      "Som kund får du tillgång till vårt förmånsprogram med unika rabatteroch förmåner. Dessutom kan du lägga till en medförsäkrad för endast 240 kr/år.",
  },
  homeViewFooterModal: {
    titleWorking: "Hur fungerar det?",
    subWorking:
      "När du har tecknat försäkringen aktiveras den om 24 timmar ochgäller sedan i ett år utan bindnings- eller uppsägningstid. Efter ett år förnyas försäkringen automatiskt för att du inte ska stå utan försäkringsskydd om olyckan är framme.",
    linkToExtarnalTerms: "* Läs närmre i förköpsinformationen.",
    infoGreenCardSystem:
      "** Med Europa avses alla länder som är anslutna till Gröna kort-systemet.",
  },
  socialView: {
    back: "Tillbaka",
    titleSocialTasks: "Hämta dina uppgifter",
    subSocialTasks:
      "Det är enkelt att teckna försäkring hos oss. Börja med att ange ditt personnummer så hämtas dina uppgifter automatiskt.",
    subSafeAndEasy: "Tryggt och enkelt!",
    inputPlaceholderSocialnr: "Personnummer (ÅÅÅÅMMDDXXXX)",
    submit: "Vidare",
  },
  checkView: {
    titleCheck: "Granska dina uppgifter ",
    emailPlaceholder: "Din e-post (namn@email.com)",
    submit: "Vidare",
  },
  signInsuranceView: {
    titlePayment: "Välj betalsätt",
    rowTitle: "Vägassistans Bil",
    period: "Försäkringsperiod:",
    premium: "Premie: 397 kr/år",
    applyAuto: "Spara 45 kr i fakturaavgift - ansök om autogiro!",
    submit: "Teckna försäkring",
    conditionsTitle: "Jag godkänner villkoren",
    conditionsInsurance:
      " och samtycker till att mina personuppgifter får lagras och behandlas av Assistansbolaget Försäkring Sverige AB (”Assistansbolaget”) i syfte att fullgöra våra åtaganden enligt försäkringsvillkoren samt att Assistansbolaget får kontakta mig via e-post, vanlig post, telefon och sms med information och marknadsföring av Assistansbolagets produkter och tjänster.",
    btnPaperInvoice: "Pappersfaktura",
    directDebit: "Autogiro",
  },
  stepView: {
    nrOne: "1",
    nrTwo: "2",
    nrThree: "3",
    nrFour: "4",
    socialNr: "Personnummer",
    socialReview: "Granska",
    socialPay: "Betala",
    socialGreat: "Tack",
  },
  paymentInsuranceView: {
    titleAuto: "Autogiro",
    titlePaper: "Faktura",
    period: "period",
    premium: "Premie:",
    pricePerYear: "kr/år",
    pricePerMonth: "kr/mån",
    quarter: "Kvartal",
    halfYear: "Halvår",
    year: "Helår",
  },
  customerSuggestionsModal: {
    modalSuggestionsTitle: "Du har redan",
  },
  customerNotFoundModal: {
    userNotFound: "Tyvärr hittade vi inte dig",
    userNotFoundText: "Dina uppgifter kunde inte hittas, vänligen försök igen",
    postInsuranceFail: "Försäkringen kunde inte tecknas",
    postInsuranceFailText: "Vi kunde tyvärr inte teckna din försäkring",
    postCoInsuranceFail: "Försäkringen kunde inte tecknas",
    postCoInsuranceFailText: "Vi kunde tyvärr inte teckna någon medförsäkrad",
    alreadySigned: "Försäkringen kunde inte tecknas",
    alreadySignedText: "Du har redan den här försäkringen",
  },
  suggestionsFinish: {
    addButton: "lägg till",
  },
  suggestion: {
    noMoreSuggestions: "Du kan tyvärr inte teckna upp någon mer försäkring",
  },
  relationsSuggestions: {
    realtionForCoCustomer:
      " Du har inga fler fordon, men kanske en försäkring för dina nära och kära",
    canNotFindMoreRelations:
      " Hittar tyvärr inga fler försäkringar som passar dig",
    youAreAdded: "Du är nu tillaggd",
  },
  vehicleSuggestions: {
    vehicleSuggestionForCustomer:
      " Andra erbjudanden som vi tror hade passat dig",
    relationsForCustomer:
      "Du har inga fler fordon, men kanske en försäkring för dina nära och kära",
    noMatchingSuggestions:
      "Hittar tyvärr inga fler försäkringar som passar dig",
    noSuggestionsFinishedPage:
      "Du har inga fler föräkringar som du kan teckna upp",
    priceYear: "kr/år",
    ordPriceSuggestions: "Ordinarie pris per år - ",
  },
  relationsSuggestionsAdded: {
    youAreAdded: "Den är nu tillaggd",
  },
};
export default strings;
