import { ApiContext } from "../../context";
import useNavigateWithRootParams from "../../hooks/useNavigateWithRootParams";
import React, { useContext } from "react";
import "./GetUserState.css";
import ModalPopupError from "./ModalPopupError";

const ModalPopUp = () => {
  const { userState, setUserState, insuranceState, coInsuranceState } =
    useContext(ApiContext);
  const navigate = useNavigateWithRootParams();

  if (userState.response) {
    if (userState.response.data.canSign === false) {
      return <ModalPopupError type="alreadySigned" />;
    } else {
      setUserState({
        loading: false,
        response: null,
        error: null,
      });
      navigate("granska");
    }
  }

  if (insuranceState.response) {
    if (insuranceState.response.status === 200) {
      navigate("klar");
    }
  }

  if (userState.error) {
    return <ModalPopupError type="userNotFound" />;
  }

  if (insuranceState.error) {
    return <ModalPopupError type="insuranceFail" />;
  }

  if (coInsuranceState.error) {
    return <ModalPopupError type="coInsuranceFail" />;
  }

  return null;
};
export default ModalPopUp;
