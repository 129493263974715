import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import strings from "../../strings";
import { usePrismicDocumentByUID } from "@prismicio/react";
import "./Suggestions.css";
import "./Relations.css";

const Relations = () => {
  const { user, socialNr } = useContext(UserContext);
  const postCoCustomer = useContext(UserContext);
  const { uid: campaignUid } = useParams();
  const { addButton } = strings.suggestionsFinish;
  const [campaign] = usePrismicDocumentByUID("campaign", `${campaignUid}`);
  const [relationsArray, setRelationsArray] = useState([]);

  useEffect(() => {
    setRelationsArray(user.relations);
  }, []);

  const onSubmit = (event, coCustomerSSN, index) => {
    relationsArray.splice(index);
    postCoCustomer.postData({
      customerCode: socialNr,
      coCustomerCode: coCustomerSSN,
      campaignUid,
    });
    event.preventDefault();
  };

  return (
    <div className="relations-container">
      <p className="relations-header">
        Erbjudande för att medförsäkra relationer
      </p>
      {relationsArray.map((relation, index) => (
        <div className="card-container" key={index}>
          <div className="card-text">
            <p className="title-bold-text">
              {relation.firstName} {relation.lastName}
            </p>
            <p className="title-regular-text"> {relation.address} </p>
            <p className="title-regular-text">
              {relation.zipCode} {relation.city}
            </p>
            <div className="campaign-price amount">
              <p className="suggestion-price">
                {campaign?.data.coinsurance_monthly_price}
              </p>
              <p className="campaign-price text">kr/månaden</p>
            </div>
            <button
              className="sign"
              onClick={(e) => onSubmit(e, relation.socialNumber, index)}
            >
              {addButton}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Relations;
