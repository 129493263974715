import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./HomePage.css";
import Logo from "../common/Logo";
import ListInfo from "../ListInfo";
import ButtonMain from "../ButtonMain";
import strings from "../../../strings";
import useNavigateWithRootParams from "../../../hooks/useNavigateWithRootParams";
import { usePrismicDocumentByUID } from "@prismicio/react";
import BackgroundImage from "../common/BackgroundImage";

const HomePage = () => {
  const navigate = useNavigateWithRootParams();
  const { submit, ordinaryPrice, pricePerYear } = strings.homeView;
  let { uid } = useParams();
  const [campaign] = usePrismicDocumentByUID("campaign", `${uid}`);

  useEffect(() => {
    if (!uid) {
      navigate("*");
    }
  }, [campaign, uid]);

  return (
    <>
      {campaign && (
        <>
          <div className="main-container main">
            <div className="logo-container">
              <Logo />
            </div>
            <div className="info-container home  tinted-background justify-between">
              <ListInfo />
              <div className="price-container">
                <div className="btn-wrapper">
                  <div className="content-price-btn ">
                    <h2 className="price">
                      {campaign.data.price} {pricePerYear}
                    </h2>
                    <p className="former-price">
                      ({ordinaryPrice} {campaign.data.ordinary_price}{" "}
                      {pricePerYear})
                    </p>
                    <ButtonMain
                      title={submit}
                      onClick={() => navigate("personnummer")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {campaign ? (
        <div className="background">
          <BackgroundImage />
        </div>
      ) : null}
    </>
  );
};

export default HomePage;
