import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/fonts/DINPro/fonts/fonts.css";
import "../src/fonts/SfPro/fonts/fonts.css";

import { ApiProvider, UserProvider } from "../src/context";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";

ReactDOM.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <ApiProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </ApiProvider>
    </PrismicProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
