import React from "react";
import { RotateSpinner } from "react-spinners-kit";
import strings from "../../strings";

export default function LoadingSpinner() {
  const { loadingFirstText } = strings.loadingSpinner;
  return (
    <div className="spinner-container">
      <div className="spinner">
        <RotateSpinner size={80} color="white" />
      </div>
      <span className="spinner-text first">{loadingFirstText}</span>
    </div>
  );
}
