import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext, ApiContext } from "../../../context";
import ButtonMain from "../ButtonMain";
import ContentTask from "../ContentTask";
import "../common/CommonStyles.css";
import { usePrismicDocumentByUID } from "@prismicio/react";
import ModalPopUp from "../ModalPopUp";
import "./SocialnrSubmitPage";
import LoadingSpinner from "../LoadingSpinner";

const SubmitSocialnrComponent = () => {
  const user = useContext(UserContext);
  const { userState } = useContext(ApiContext);
  let { uid } = useParams();
  const [campaign, { state }] = usePrismicDocumentByUID("campaign", `${uid}`);
  const [socialNrState, setSocialNrState] = useState("");

  const [danger, setDanger] = useState(false);
  const [warningText, setWarningText] = useState("");

  const handleChangeUser = (event) => {
    const value = event.target.value;
    if (value.length !== 12) {
      setDanger(true);
    }
    setSocialNrState(event.target.value);
  };

  const submitUser = (event) => {
    event.preventDefault();
    setWarningText(false);
    setDanger(false);
    const reg = /^\d{6,8}[-|(\s)]{0,1}\d{4}$/;
    if (socialNrState.length !== 12) {
      setDanger(true);
      setWarningText("Ogiltigt personnummer");
    } else if (socialNrState === "") {
      setDanger(true);
      setWarningText("Måste fylla i personnummer");
    } else if (reg.test(socialNrState) === false) {
      setDanger(true);
      setWarningText("Ogiltigt personnummer");
    } else {
      setDanger(false);
      setWarningText(false);
      user.fetchData(socialNrState, campaign.data.article_type);
    }
  };

  if (state === "loading") {
    return null;
  }
  return (
    <>
      {userState.loading && <LoadingSpinner />}
      <ContentTask />
      <div className="input-container">
        <div className="input-wrapper">
          <form className="form-input" noValidate>
            {danger && (
              <p className="danger-container text-danger">{warningText}</p>
            )}
            <input
              className={`input-user  ${warningText ? "warningText" : ""}`}
              type="text"
              name="personnumer"
              placeholder="Personnummer (ÅÅÅÅMMDDXXXX)"
              htmlFor="socialNr"
              inputMode="numeric"
              onChange={handleChangeUser}
              required
            />
            <ButtonMain onClick={submitUser} type="submit" title={"Vidare"} />
          </form>
          <ModalPopUp />
        </div>
      </div>
    </>
  );
};

export default SubmitSocialnrComponent;
