import React from "react";

import { useState, useEffect } from "react";
import ModalInsurance from "./ModalInsurance";
import "./InfoPopup.css";
import strings from "../../strings";

const ModalPopupError = (props) => {
  const {
    userNotFound,
    userNotFoundText,
    postInsuranceFail,
    postInsuranceFailText,
    postCoInsuranceFail,
    postCoInsuranceFailText,
    alreadySigned,
    alreadySignedText,
  } = strings.customerNotFoundModal;

  const [timePopup, setTimePopup] = useState(false);
  const ToggleModal = () => setTimePopup(!timePopup);

  useEffect(() => {
    setTimeout(() => {
      setTimePopup(true);
    }, 500);
  }, []);

  if (props.type === "alreadySigned") {
    return (
      <>
        <ModalInsurance
          show={timePopup}
          close={ToggleModal}
          title={`${alreadySigned}`}
        >
          <span>{alreadySignedText}</span>
        </ModalInsurance>
      </>
    );
  }
  if (props.type === "userNotFound") {
    return (
      <>
        <ModalInsurance
          show={timePopup}
          close={ToggleModal}
          title={`${userNotFound}`}
        >
          <span>{userNotFoundText}</span>
        </ModalInsurance>
      </>
    );
  }

  if (props.type === "insuranceFail") {
    return (
      <>
        <ModalInsurance
          show={timePopup}
          close={ToggleModal}
          title={`${postInsuranceFail}`}
        >
          <span>{postInsuranceFailText}</span>
        </ModalInsurance>
      </>
    );
  }

  if (props.type === "coInsuranceFail") {
    return (
      <>
        <ModalInsurance
          show={timePopup}
          close={ToggleModal}
          title={`${postCoInsuranceFail}`}
        >
          <span>{postCoInsuranceFailText}</span>
        </ModalInsurance>
      </>
    );
  }
};

export default ModalPopupError;
