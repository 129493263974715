import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Logo from "../../components/common/Logo";
import "./Layout.css";
import BackgroundImage from "../common/BackgroundImage";
import HighlightedNumbers from "../../components/HighlightedNumbers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePrismicDocumentByUID } from "@prismicio/react";
import { useParams } from "react-router-dom";

const Layout = () => {
  let { uid } = useParams();
  const [campaign] = usePrismicDocumentByUID("campaign", `${uid}`);

  const [isInsurancePage, setIsInsurancePage] = useState(false);

  const checkPathname = (variable) => {
    return window.location.pathname.includes(variable);
  };

  useEffect(() => {
    const hasInsurancePage = checkPathname("forsakring");
    setIsInsurancePage(hasInsurancePage);
  }, [window.location.pathname]);

  return (
    <>
      <div className="layout-contaioner layout">
        <div className="arrow-container">
          <Link to={-1}>
            <FontAwesomeIcon icon="arrow-left" className="arrow-left" />
          </Link>
          <div className="logo-container-wrapper">
            <Logo />
          </div>
        </div>
        <main
          className={`main-outlet outlet ${
            isInsurancePage ? "" : "justify-content"
          }`}
        >
          <HighlightedNumbers />
          <Outlet />
        </main>
      </div>
      {campaign ? (
        <div className="background">
          <BackgroundImage />
        </div>
      ) : null}
    </>
  );
};

export default Layout;
